.dashBoard {
  padding: 30px 0 0 30px;
  width: calc(100% - 30px);
  background: #fff;
}

.dashBoard .headertitle h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1f1f1f;
}

.dashBoard .contentWrapper {
  width: 100%;
  /* background: #333; */
  height: calc(100vh - 76px);
  display: flex;
}

.contentWrapper .studentScheLeftPane {
  width: 70%;
  margin-right: 20px;
}

.contentWrapper .studentScheRightPane {
  width: 30%;
  margin-top: 10px;
}

.boxarea {
  display: flex;
  margin: 0 -10px 20px;
}

.boxarea .boxey {
  padding: 17px 20px;
  border-radius: 8px;
  margin: 10px;
  min-height: 140px;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.boxarea .boxey.aligntop {
  justify-content: flex-start;
}
.aligntop span.titled {
  padding-top: 10px;
  max-width: 180px;
  margin-right: 30px;
}

.smileyicon {
  position: absolute;
  bottom: 15px;
  right: 20px;
}
.boxarea .boxey span {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.boxarea .boxey.lightblue {
  background: #d7ecff;
}

.boxarea .boxey.lightyellow {
  background: #fff6e0;
}
.boxarea .boxey.lightyellow span {
  color: #c3831c;
}

.boxarea .boxey.lightblue span {
  color: #2366a0;
}

.boxarea .boxey.grey {
  background: #f2f2f2;
  max-width: 70px;
  align-items: center;
}

.boxarea .boxey span.num {
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
}

.boxarea .boxey.lightblue span.num {
  color: #2366a0;
}

.boxarea .boxey.lightyellow span.num {
  color: #c3831c;
}

.studentScheRightPane {
  background: #faf8f3;
  border-radius: 10px 0 0 0;
}
.todays-meeting-details span {
  word-break: break-all;
}
span.todays-meeting-menu {
  position: absolute;
  right: 10px;
}

.calanderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ede8da;
  padding: 20px 26px 10px;
}

.dayanddate {
  display: flex;
  align-items: center;
  min-width: 220px;
  justify-content: space-between;
}
.dayanddate h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  color: #1f1f1f;
}

.dayanddate h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #9d9d9d;
}

.rightandleftaarorw {
  padding: 0px 10px;
}

.rightandleftaarorw span {
  padding: 10px 5px;
  cursor: pointer;
}

.timezone-calendar {
  margin-top: 25px;
  margin-right: 15px;
  color: #9b9494;
}

.calanderul {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
}
.calanderul li {
  border-bottom: 1px solid #ede8da;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 18px;
  color: #9d9d9d;
}

.meetings-dashbrd .fc .fc-scrollgrid,
.calanderboxWrapper .fc .fc-scrollgrid {
  border: none !important;
}

.meetings-dashbrd .fc-timegrid-slot-lane {
  background-color: #faf8f3;
}

.meetings-dashbrd .fc-timegrid-slot-label {
  background-color: #faf8f3;
}
.meetings-dashbrd .fc-timegrid-slot-label-cushion {
  color: #9d9d9d;
}
.meetings-dashbrd .fc .fc-timegrid-slot-minor,
.calanderboxWrapper .fc .fc-timegrid-slot-minor {
  border-top-style: none !important;
}
.meetings-dashbrd .fc-timegrid-event-harness-inset .fc-timegrid-event,
.meetings-dashbrd .fc-timegrid-event.fc-event-mirror,
.meetings-dashbrd .fc-timegrid-more-link,
.calanderboxWrapper .fc-timegrid-event-harness-inset .fc-timegrid-event,
.calanderboxWrapper .fc-timegrid-event.fc-event-mirror,
.calanderboxWrapper .fc-timegrid-more-link {
  border-radius: 15px;
  padding: 6px;
}

.calanderboxWrapper.popcalendar .fc .fc-timegrid-axis-cushion,
.calanderboxWrapper.popcalendar .fc .fc-timegrid-slot-label-cushion {
  font-weight: 400;
  font-size: 12px;
  color: #868686;
}
.calanderboxWrapper.calendar-wide .fc .fc-timegrid-axis-cushion,
.calanderboxWrapper.calendar-wide .fc .fc-timegrid-slot-label-cushion {
  font-weight: 400;
  font-size: 12px;
  color: #868686;
}

.calanderboxWrapper .fc .fc-col-header-cell-cushion {
  padding: 15px 5px;
}

.calanderboxWrapper .fc .fc-col-header-cell-cushion div span:nth-child(2) {
  color: #868686;
  font-size: 16px;
  line-height: 26px;
}
.meetings-dashbrd .fc-timegrid-event .fc-event-main {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.meetings-dashbrd .fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: left !important;
  padding-left: 4px;
}
.meetings-dashbrd .fc-scrollgrid-shrink-cushion {
  font-size: 12px;
}

.SchedulecalanderModal .studentInfo,
.SchedulecalanderModal .studentInfo-labelInput {
  display: flex;
  flex-direction: column;
}

.SchedulecalanderModal .studentInfo li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.SchedulecalanderModal .studentInfo-labelInput li {
  margin-bottom: 20px;
}
.SchedulecalanderModal .studentInfo-labelInput label {
  font-size: 14px;
  line-height: 21px;
  color: #6d6d6d;
}

.SchedulecalanderModal .studentInfo-labelInput input[type='text'] {
  font-size: 14px;
  line-height: 21px;
  color: #6d6d6d;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 6px 10px;
  margin: 5px 0;
}
.SchedulecalanderModal .studentInfo-labelInput select {
  font-size: 14px;
  line-height: 21px;
  color: #6d6d6d;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 6px 10px;
  margin: 5px 0;
}

.SchedulecalanderModal .studentInfo-labelInput li {
  display: flex;
  flex-direction: column;
}
.SchedulecalanderModal .studentInfo li i {
  background: #f4f4f4;
  border-radius: 8px;
  display: inline-flex;
  align-self: flex-start;
  padding: 5px;
  margin-right: 10px;
  min-width: 22px;
  min-height: 22px;
  align-items: center;
  justify-content: center;
}
.SchedulecalanderModal input::placeholder,
.SchedulecalanderModal textarea::placeholder {
  font-size: 14px !important;
}
.SchedulecalanderModal select {
  border: none;
  font-size: 14px;
  width: auto;
}

.SchedulecalanderModal .meetingDetails div {
  margin-bottom: 10px;
}

.SchedulecalanderModal.popheader {
  margin-bottom: 14px;
}

.meeting-info-notes p {
  max-width: 320px;
  line-height: 1.35;
  word-break: break-all;
}
.popheader .formFields input {
  border: none;
  width: 80%;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #111;
}
.SchedulecalanderModal.popheader h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #111;
  max-width: 500px;
  width: calc(100% - 30px);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}
.popheader .monthSelector {
  text-align: right;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #878787;
  margin-left: auto;
}

.calendarModal-subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide {
  display: none;
}
.popheader .formFields input::placeholder {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #cccccc;
}

.SchedulecalanderModal .meetingDetails button {
  background: #1367b2;
  border-radius: 20px;
  border-radius: 60px;
  color: #fff;
  border: none;
  padding: 5px 22px;
  cursor: pointer;
}
.meetingDetails button a {
  text-decoration: none;
  color: white;
}
.SchedulecalanderModal .meetingDetails button:hover {
  background: #1d5ea6;
}
.SchedulecalanderModal {
  font-size: 14px;
}

.SchedulecalanderModal .meetLink {
  color: #999999;
}

.meetingdate {
  margin-right: 10px;
}

.SchedulecalanderModal .userName {
  color: #1d5ea6;
}
.SchedulecalanderModal .userName span {
  color: #999999;
}

.nborder {
  border: none;
}
.SchedulecalanderModal textarea {
  border: none;
  width: 100%;
  font-size: 14px;
}

.popfooter span.err {
  font-family: 'Poppins', sans-serif;
  padding: 0 10px;
}

.popfooter .btn.cancelbtn {
  color: #999999;
}

.SchedulecalanderModal .popfooter {
  background: #fafafa;
}
/* CALANDER PAGE  */
.schedulerCalendarWide {
  padding: 30px 30px;
  width: 100%;
}

.curpointer {
  cursor: pointer;
}

.boxarea .boxey.grey.curpointer:hover {
  background-color: #e3e3e3;
}

.schedulerCalanderHeader {
  display: flex;
  align-items: center;
}

.schedulerCalendarWide .schedulerCalanderHeader .title,
.schedulerCalendarWide .schedulerCalanderHeader .monthSelector {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1f1f1f;
}
.schedulerCalendarWide .schedulerCalanderHeader .monthSelector {
  color: #878787;
}

.monthSelector {
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.monthSelector span {
  padding: 0;
  width: 36px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  margin: 0 0 0 10px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.monthSelector span:hover {
  background: #faf6e9;
}

.calanderboxWrapper {
  border-radius: 12px;
  background: #fff;
  border: 1px solid #e3e3e3;
  display: flex;
  height: calc(100vh - 144px);
  margin-top: 24px;
  overflow-y: auto;
}

.calanderboxWrapper.popcalendar {
  height: calc(100vh - 300px);
}
.SchedulecalanderModal p {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.noMeetingWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 300px);
  justify-content: center;
}

.noMeetingWrapper h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #cacaca;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 0;
}

@media (min-width: 1400px) {
  .dashBoard {
    padding: 40px 0 0 40px;
    width: calc(100% - 40px);
    background: #fff;
  }

  .schedulerCalendarWide {
    padding: 40px;
    width: 100%;
  }
}
