.page-sidebar.sidebar {
  background: #000000;
  width: 100%;
  max-width: 56px;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  position: sticky;
  top: 0;
  z-index: 6;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: center;
}

.menu {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
}
.menu li {
  margin-bottom: 20px;
}

.menu li.final {
  margin-top: auto;
}

.menu li span {
  padding: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu li span svg {
  outline: none;
}
.menu li:hover span {
  background: #393939;
  border-radius: 50%;
}

@media (min-width: 1400px) {
  .sidebar-header {
    margin-top: 41px;
  }
}
