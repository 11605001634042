/* dashboard */

.dashboardAlign {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px !important;
}

.dashboardAlign h3 {
  font-size: 28px;
}

.pico-wrap {
  display: unset;
  font-family: 'Poppins', sans-serif;
}

.pico-wrap.fullwidth {
  display: flex;
}
.pico-wrap .pico-contentWrapper {
  width: 100%;
  padding: 0;
}
.pico-wrap.fullwidth .pico-contentWrapper {
  width: calc(100% - 52px);
  padding: 30px 22px 0px 30px;
}

.pico-wrap.fullwidth .pico-container {
  max-width: unset;
  width: calc(100%);
  /* padding: 40px 20px 0 40px; */
}

.pico-wrap.fullwidth .pico-container.overviewList {
  width: calc(100% - 62px);
  padding: 40px 20px 0 40px;
}

.studentviewHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
}

.studentviewHeader .headerTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
}

/* SEARCH SECTION  */

.searchSection {
  border: 1px solid #e3e3e3;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  max-width: 260px;
}

.searchSection.search-active {
  border: 1px solid #5b94c6;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgb(64 60 67 / 20%);
  border-color: rgba(223, 225, 229, 0);
}

.searchSection .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
}

.searchSection .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  border: none;
  background: transparent;
  line-height: 32px;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.form-control:focus {
  border-color: transparent;
  outline: 0;
}

.searchSection .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
  border-collapse: separate;
  border: none;
}

.search-btn {
  border: none;
  background: none;
  margin-top: 2px;
  cursor: pointer;
}

.table-responsive {
  display: block;
  width: 100%;
  /* overflow-x: auto; */
  /* -webkit-overflow-scrolling: touch; */
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 700;
  font-size: 12px;
  /* text-transform: uppercase; */
  color: #a0a0a0;
  vertical-align: bottom;
  border-bottom: 2px solid #e8ebf1;
  text-align: left;
}

.table thead th.selectedTh {
  color: #1367b2;
}

.table td,
.table th {
  /* vertical-align: middle; */
  line-height: 1.5;
  /* white-space: nowrap; */
  padding: 0.875rem 0.2375rem;
  border-top: 1px solid #e8ebf1;
  font-size: 12px;
}
.table th:nth-child(3){
  width:200px
}
.table tr td:nth-child(3){
  width:200px
}

.table td {
  font-size: 0.75rem;
}

.table td:first-child,
.table th:first-child {
  min-width: 64px;
  width: 64px;
}

.table td img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.py-1 {
  padding-top: 0.25rem !important;
}
.py-1 {
  padding-bottom: 0.25rem !important;
}

.avatar-name {
  display: flex;
  align-items: center;
}

.avatar-name img {
  margin: 0 10px;
  margin-left: -56px;
}

.table tbody {
  display: block;
  max-height: calc(100vh - 210px);
  overflow: auto;
  width: 100%;
}

.table thead,
.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* SCROLL BAR STYLING */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(91, 91, 91, 0.3);
  box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.3);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(91, 91, 91, 0.3);
}

/* SCROLL BAR STYLING ENDS*/

.genralTags,
.academicStandingTags,
.asigncounsTag,
.stutags {
  display: flex;
  flex-wrap: wrap;
}

.genralTags li,
.academicStandingTags li,
.asigncounsTag li,
.stutags li {
  margin: 5px 5px 5px 5px;
}

.genralTags li:first-child,
.academicStandingTags li:first-child,
.asigncounsTag li:first-child,
.stutags li:first-child {
  margin: 5px 5px 5px 0px;
}
.academicStandingTags li span,
.genralTags li span,
.asigncounsTag li span,
.stutags li span {
  border-radius: 100px;
  background: #ecfadf;
  padding: 4px 13px;
  line-height: 20px;
  font-size: 12px;
}

.academicStandingTags li span.good {
  background: #ecfadf;
  color: #63a71f;
}
.academicStandingTags li span.probation {
  background: #ffeaea;
  color: #c36363;
}
.academicStandingTags li span.dismissed {
  background: #f1f1f1;
  color: #808080;
}

.asigncounsTag li span {
  background: #ffbc57;
  color: #8d8d8d;
}

.genralTags li {
  display: inline;
  width: auto;
}
.genralTags li span {
  background: #e7f3ff;
  color: #5b94c6;
}

.genralTags li span {
  background: #e7f3ff;
  color: #5b94c6;
}

.yellowspc.genralTags li span {
  background: #fff2de;
  color: #aa782e;
}

.stutags li {
  width: unset !important;
}

.stutags li span {
  font-size: 12px;
  display: inline-block;
}

.stutags li span.blue {
  background: #e7f3ff;
  color: #5b94c6;
}

.stutags li span.speccounprog {
  background: #fff2de;
  color: #aa782e;
}

.stutags li span.probation {
  background: #ffeaea;
  color: #c36363;
  font-size: 12px;
}

.stutags li span.good {
  color: #63a71f;
  background: #ecfadf;
}

.stutags li span.dismissed {
  color: #808080;
  background: #f1f1f1;
}

.studentViewContent {
  display: flex;
}

.profiledettailcard {
  margin: -40px 0 0px -40px;
  box-shadow: 2px 0px 10px rgba(219, 219, 219, 0.25);
  padding: 60px 20px 10px 20px;
  text-align: center;
  border-radius: 20px 20px 0 0;
}

.profiledettailcard .profile-image-container {
  max-width: 150px;
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  width: 100%;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #1367b2;
  display: flex;
}

.profiledettailcard .profile-image-container img {
  max-width: 150px;
  max-height: 150px;
}
.profiledettailcard {
  width: 100%;
  max-width: 340px;
  height: calc(100vh - 75px);
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(219, 219, 219, 0.25);
}
.rightContentCard {
  width: 100%;
  overflow: none;
  height: calc(100vh - 40px);
}

.footerbox {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
}

/* OVERVIEW PAGE  */

.pico-wrap.fullwidth .pico-contentWrapper.overviewList {
  padding: 0 !important;
  width: 100%;
}

.profiledettailcard h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding: 30px 0 0;
}

.profiledettailcard h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.profiledettailcard h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px 0 20px 0;
}

.profiledettailcard p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}

.contactIcons {
  margin-bottom: 30px;
}
.contactIcons li {
  display: inline;
  padding: 0 5px;
}

.contactIcons li span {
  width: 40px;
  height: 40px;
  background: #efefef;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.contactIcons li span:hover {
  background: #dddddd;
}

.rightContentCard {
  padding: 0 10px 0 30px;
}

.topBreadcrumbSection {
  margin-bottom: 24px;
}
.topBreadcrumbSection a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  color: #2e7bc0;
}

.topBreadcrumbSection a span {
  margin: 0 10px 0 0;
  display: flex;
}

.navMenu {
  background: #f2f9ff;
  border: 1px solid #d0e9ff;
  box-sizing: border-box;
  border-radius: 100px;
  display: inline-flex;
  padding: 9px 16px;
  margin-bottom: 17px;
}
.navMenu ul {
  display: flex;
}
.navMenu ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #bdbdbd;
  padding: 9px 16px;
}

.navMenu ul li.active {
  background: #1367b2;
  border-radius: 50px;
  color: #fff;
}
.navMenu ul li a {
  text-decoration: none;
  color: #bdbdbd;
}

.navMenu ul li.active a {
  color: #fff;
}

.studentDetails-tasksRow,
.progress-quicklinkrow {
  display: flex;
  width: 100%;
}

/* .studentDetails-tasksRow {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 170px);
} */

.studentDetailsBox,
.studentTaskBox,
.progressBox,
.quicklinkBox {
  width: calc(100% - 64px);
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 4px 10px rgba(237, 237, 237, 0.5);
  border-radius: 8px;
  margin: 0px 20px 0px 0;
  padding: 20px 32px;
}

.studentDetailsBox {
  width: 50%;
  max-height: calc(100vh - 250px);
  position: relative;
  padding: 20px 5px 50px 20px;
}

/* SCROLL BAR STYLING */
.studentDetailsBox ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* Track */
.studentDetailsBox ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
.studentDetailsBox ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(91, 91, 91, 0.1);
  box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(91, 91, 91, 0.1);
}
.studentDetailsBox ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(91, 91, 91, 0.1);
}

.studentDetailUl {
  height: calc(100vh - 350px);
  overflow-y: auto;
}

.studenTaskandprogressWrapper {
  width: 100%;
}

.quicklinkBox {
  width: calc(100% - 64px);
}

.studentTaskBox {
  width: calc(100% - 44px);
  padding: 20px 10px 50px 32px;
}

.studentDetailsBox h3.boxTitles,
.studentTaskBox h3.boxTitles,
.progressBox h3.boxTitles,
.quicklinkBox h3.boxTitles {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #212121;
  margin: 5px 0 15px;
}
.studentDetailUl {
  margin-bottom: 20px;
}
.studentDetailUl li.lirow {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.studentDetailUl li.lirow .genralTags li span {
  font-size: 12px;
}
.studentDetailUl li > div {
  width: 100%;
  margin-bottom: 20px;
}

.studentDetailUl li .superTitle {
  font-size: 14px;
  line-height: 21px;
  color: #6d6d6d;
  margin-bottom: 6px;
}

.boxfooterd {
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0;
}
.boxfooterd .text-center {
  text-align: center;
  display: block;
}

.progressBox {
  position: relative;
  padding: 20px 32px 70px;
  margin-bottom: 17px;
}

.blueroundedbtn {
  background: #1367b2 !important;
  border-radius: 20px;
  color: #fff !important;
  line-height: 39px;
  padding: 0 20px;
  font-size: 14px;
}
.disabled-roundedbtn {
  background: #999 !important;
  border-radius: 20px;
  color: #fff !important;
  line-height: 39px;
  padding: 0 20px;
  font-size: 14px;
}

.degreeeauditbtn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.studentDetailUl li .academicStandingTags li span.good {
  font-size: 12px;
}
.studentTaskBox .tskUlLi {
  max-height: calc(100vh - 600px);
  overflow-y: auto;
  padding-right: 15px;
}

.studentTaskBox .tskUlLi li {
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px;
  line-height: 18px;
  padding: 9px 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.studentTaskBox .tskUlLi li span {
  background: #f3f3f3;
  border-radius: 100px;
  padding: 4px 13px;
  line-height: 20px;
}

button.btn.transparentbtn {
  color: #1367b2;
  font-size: 14px;
}
.colorprimary {
  color: #1367b2;
}
.colorgray {
  color: #808080 !important;
}

.progresHeaderDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.custom-select {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 5px 20px;
  line-height: 36px;
}
.popselect select {
  width: 100%;
}

.custom-goal p {
  font-weight: normal;
}

.custom-goal p strong {
  font-weight: 600;
}

.custom-select option {
  font-size: 14px;
  line-height: 21px;
}

.custom-select select {
  border: none;
}

.numberorpercentFilter ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberorpercentFilter ul li {
  padding: 0 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1367b2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1367b2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pieanddatawrapper,
.piewrapper,
.datawrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.datawrapper {
  margin-bottom: 20px;
}

.piewrapper {
  flex-direction: column;
}

.piewrapper p,
.datawrapper h4,
.datawrapper p {
  text-align: center;
}

.piewrapper p.pieunittext {
  padding: 10px 0 0 0;
}

.piewrapper p.subdetails {
  padding: 0 !important;
}

.datawrapper h4 {
  font-weight: 500;
  font-size: 22.08px;
  line-height: 33px;
  color: #5b9a1c;
}

.piewrapper p,
.datawrapper p {
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  padding: 10px 0;
}
.datawrapper p {
  padding: 0;
}
.piewrapper,
.datawrapper {
  max-width: 350px;
}
.piewrapper,
.datawrapper,
.databox {
  width: 100%;
}

.quicklinkBox .quicklinks li a {
  color: #1367b2;
  padding: 5px 0;
  display: block;
  text-decoration: none;
}

.quicklinkBox .quicklinks li a:hover {
  text-decoration: underline;
}

/* CLASSES  */

.topBreadcrumbSection {
  display: flex;
}

.topSearch {
  padding: 0px;
  margin-left: auto;
}

.searchbox {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 0 10px 0 0;
}
.searchbox.search-active {
  border: 1px solid #5b94c6;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgb(64 60 67 / 20%);
  border-color: rgba(223, 225, 229, 0);
}

.searchform {
  display: flex;
  align-items: center;
}

.topSearch input[type='text'] {
  border: none;
  line-height: 32px;
  border-radius: 100px 0 0 100px;
  padding: 2px 20px;
}
.topSearch input[type='text']:focus {
  border: none;
  outline: none;
}

.tecoIco {
  display: flex;
}

.topSearch button {
  background: none;
  border: none;
  cursor: pointer;
}

/* STUDENT CLASSES  */

.studentclasseswrapper {
  width: calc(100% - 48px);
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 4px 10px rgba(237, 237, 237, 0.5);
  border-radius: 8px;

  padding: 24px;
}

.studentclasseswrapper .table tbody {
  max-height: calc(100vh - 330px);
  overflow-y: auto;
}

.rightContentCard.studentclasses {
  height: unset;
  overflow-y: none !important;
}

span.classtag {
  padding: 0 13px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 100px;
}
span.classtag.wi-blue {
  background: #f2f9ff;
  color: #1367b2;
}

span.classtag.spr-green {
  background: #e6f9e3;
  color: #4ca53c;
}

span.classtag.fa-yellow {
  background: #fbf3d6;
  color: #80670d;
}

.profiledatas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
}
.profiledatas li {
  width: 100%;
  margin-bottom: 20px;
}

.profiledatas li.twocol {
  width: 50%;
}

.profiledatas li span {
  color: red;
}

.txt-left {
  text-align: left;
}

/* MODAL  */

.titleTxtPdf,
.content {
  position: relative;
}

.titleTxtPdf {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.content span.closepdfbtn {
  position: absolute;
  top: -7px;
  right: 15px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px;
}

.content span.closepdfbtn:hover {
  background: #e5e5e5;
  border-radius: 100px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  display: flex;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}
.popup {
  margin: auto;
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  position: relative;
  transition: all 5s ease-in-out;
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popheaderspan {
  position: absolute;
  top: 30px;
  right: 30px;
}

.transpBtn {
  background: transparent !important;
  border: none;
  cursor: pointer;
}

.popheader {
  margin-bottom: 30px;
}
.ReactModal__Content {
  position: relative;
}
.ReactModal__Content .popheader2 {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 100%;
  min-width: 300px;
  z-index: 999;
  font-family: 'Poppins', sans-serif;
}

.popheader h2 {
  padding: 15px 0 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
}
.popheader h2 span {
  padding: 0 10px;
  cursor: pointer;
}

.popheader h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1f1f1f;
}

.studentDetailss {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  width: 100%;
  justify-content: space-between;
}

.studentDetailss.meetings {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0 !important;
  height: max-content;
}

.detailitem textarea {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px;
  width: calc(100% - 20px);
}
.noteScoll {
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 13px;
  line-height: 1.35;
  padding-right: 10px;
}
.meetingsNotescolmn {
  width: 100%;
  max-width: 320px;
}

.studentDetailss li {
  width: 33%;
  margin-bottom: 10px;
}

.studentDetailss.meetings li {
  width: 48%;
}

.meetingsNotescolmn li {
  margin-bottom: 10px;
}
.content {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 60px;
}

.content.editModalContent {
  display: flex;
  margin-bottom: 0;
  justify-content: space-between;
}
.content ul.details li {
  padding-bottom: 20px;
}

.studentDetailss.meetings .detailitem .superTitle,
.meetingsNotescolmn .detailitem .superTitle {
  margin-bottom: 5px;
}
.detailitem .superTitle {
  font-size: 14px;
  line-height: 21px;
  color: #6d6d6d;
}

.detailitem p {
  word-break: break-all;
}

.detailitem h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}

.popfooter {
  display: flex;
  justify-content: flex-end;
  background: #fafafa;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 40px);
  padding: 20px;
  align-items: center;
}
.btnwrapper {
  display: flex;
}
.errorMessage {
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  color: #db1414;
  font-size: 12px;
}

.transparentbtn {
  background: transparent;
  color: #999999;
}

.popfooter button.btn.transparentbtn {
  background: transparent;
  color: #999999;
  font-size: 14px;
}

/* DOCUMENT PAGE  */

.tecoicon {
  margin: 0 10px;
  transform: rotate(0deg);
}

.rotated {
  transform: rotate(180deg);
  display: inline-block;
}

.studentDocument {
  margin: 0;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.studentDocument .documentUl {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.studentDocument .documentUl li {
  width: 15%;
  min-width: 188px;
  max-width: 196px;
  padding: 0px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.studentDocument .documentUl li .docThmb {
  width: 100%;
  background: #ffffff;
  border: 0.993127px solid rgba(255, 255, 255, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 2.79825px 41.9737px rgba(182, 182, 182, 0.25);
  border-radius: 11.9175px;
  overflow: hidden;
  padding: 10px;
  min-height: 263px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 33vh; */
}

.studentDocument .documentUl li:first-child {
  margin-left: 0;
}
.studentDocument .documentUl li img {
  width: 100%;
  /* height: 30vh !important; */
}

.docThmb .linkAnnotation {
  display: none;
}
.docThmb {
  position: relative;
}
.react-pdf__Page__annotations {
  position: absolute;
  bottom: 0;
}
.docThmb img {
  max-height: 240px;
}

.docThmb a.viewfile,
.popheaderspan a.viewfile {
  color: #fff;
  background: #1367b2;
  border-radius: 50px;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 12px;
}
.studentDocument .documentUl li .pdf-viewer {
  margin-bottom: 0 !important;
}
.studentDocument .documentUl li .pdf-viewer canvas {
  width: 100% !important;
  /* height: 30vh !important; */
  padding-bottom: 0 !important;
  max-height: 240px;
}
.pdf-viewerFull canvas {
  width: 100% !important;
  height: auto !important;
}
.studentDocument .documentUl li h4 {
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  line-height: 22px;
  overflow-wrap: break-word;
}

/* MEETING INFO  */

.studentMeetings {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 0;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.studentMeetings .meetingRow {
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 4px 10px rgba(237, 237, 237, 0.5);
  border-radius: 8px;
  padding: 30px;
}

.meetingContainer {
  position: relative;
}

.meetingContainer span.editIcon {
  position: absolute;
  right: 0px;
  top: 0px;
}

.meetingContainer h3.datetxt {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 15px;
  display: flex;
}

.meetingContainer h3.datetxt span.timetxt {
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  padding: 0 30px;
  display: inline-flex;
  align-items: center;
}
.meetingContainer h3.datetxt span.timetxt i {
  margin: 0 10px;
  height: 21px;
}

.meetingContainer .meetingColumn {
  display: flex;
  flex-direction: column;
}
.meetingContainer .meetingColumn .column {
  width: 100%;
}

.editIcon {
  padding: 10px;
  cursor: pointer;
}
.meetingContainer .MeetingDetails {
  display: flex;
  flex-wrap: wrap;
}
.meetingContainer .MeetingDetails li.infoItem {
  padding-right: 10px;
  width: calc(50% - 10px) !important;
}

.meetingContainer .firstColumn .MeetingDetails .infoItem {
  margin-bottom: 10px;
}

.column .infoItem .superTitle {
  font-size: 14px;
  line-height: 24px;
  color: #6d6d6d;
  font-weight: normal;
}

.column .infoItem h3 {
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  font-weight: 600;
  word-break: break-all;
}

@media (min-width: 1200px) {
  .meetingContainer .meetingColumn {
    flex-direction: row;
  }
}

@media (min-width: 1400px) {
  .pico-wrap.fullwidth .pico-contentWrapper {
    width: calc(100% - 62px);
    padding: 40px 22px 0px 40px;
  }
}
