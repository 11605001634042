.boxarea.settings {
  width: 500px;
}
.boxey.lightblue.settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 100px;
  max-width: 100%;
  cursor: pointer;
}
.boxey.lightyellow.settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 100px;
  max-width: 100%;
  cursor: pointer;
}
.settings a {
  text-decoration: none;
  color: inherit;
}
.edit-dflt-meet-setng {
  margin: auto;
}

.settings.studentScheLeftPane {
  display: flex;
  flex-direction: column;
}

.errordivs {
  width: 100%;
}
.errordivs .err {
  color: #ba3750;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.1%;
  width: 100%;
  max-width: 230px;
  margin-right: 20px;
}

.SettingsPage {
  padding-right: 40px;
}

.authBoxesWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
}
.authBoxesWrapper .autoBoxItem {
  width: calc(100% - 50px);
  margin: 0 10px;
}
.autoBoxItemWrapper {
  width: 100%;
  max-width: 920px;
  min-height: 400px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.authBoxesWrapper .autoBoxItem {
  width: calc(100% - 70px);
  background: #f4f4f4;
  margin: 10px;
  border-radius: 10px;
  padding: 25px;
  cursor: pointer;
  min-height: 160px;
}
.autoBoxItem h4 {
  font-weight: 500;
  font-size: 21.4805px;
  line-height: 32px;
  color: #6d6d6d;
  position: relative;
}

.autoBoxItem h4 span {
  background: #e6f9e3;
  border: 1px solid #85c67b;
  box-sizing: border-box;
  border-radius: 50px;
  min-height: 20px;
  min-width: 50px;
  text-align: center;
  padding: 2px 20px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 18px;
  /* margin-top: -20px; */
  position: relative;
  position: absolute;
  bottom: 4px;
}
.autoBoxItem span {
  display: inline-block;
  margin-bottom: 10px;
}
.autoBoxItem a {
  text-decoration: none;
  cursor: pointer;
}
.autoBoxItem:hover {
  background-color: #e5e5e5;
}
.errordivs {
  position: fixed;
}
.edit-dflt-meet-setng .studentInfo-labelInput {
  width: 500px;
}
.edit-dflt-meet-setng .edit-stngs-btn {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .autoBoxItemWrapper {
    display: unset;
  }

  .autoBoxItem h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6d6d6d;
  }
  .SettingsPage {
    padding-right: 20px;
  }
}
