/* RESET PASSWORD  */

.resetImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formPico.resetpassword h3 {
  padding-bottom: 0;
}

.formPico.resetpassword p {
  padding-bottom: 40px;
  font-size: 18px;
  line-height: 27px;
  max-width: 361px;
}
