body {
  background: #faf8f3;
}

* {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.__react_component_tooltip {
  font-weight: 500;
}
.pico-container {
  max-width: 1330px;
  margin: 0 auto;
}
.pico-container.flexcontainer {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  background: #faf8f3;
  /* min-height: 100vh; */
  padding-top: 60px;
}

.pico-contentWrapper.whitebg {
  background: #fff;
}

.pico-header {
  width: 100%;
  /* position: absolute; */
}

.pico-contentWrapper .pico-formwrapper {
  width: 100%;
}

.pico-leftImage {
  text-align: center;
  width: 100%;
}

.pico-leftImage img {
  width: 100%;
  max-width: 578px;
  margin: 0 auto;
}

.formPico h3 {
  padding-bottom: 20px;
}

.formPico h3.h3btmpad {
  padding-bottom: 0px;
}

.pico-header {
  /* position: absolute; */
  top: 0;
  left: 0;
}

/* NAVBAR  */

.navbar {
  width: 100%;
  z-index: 9999;
  max-width: 1330px;
  margin: 0 auto;
  padding: 40px 0px 20px 0px;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-fixed-top {
  /* position: absolute !important; */
  border-bottom: none;
  transition: background-color 0.25s ease 0s;
}
.navbar > .container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  max-width: 1330px;
  margin: 0 auto;
  padding: 0;
}
.navbar-brand {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 800;
  color: #071e55;
}

.navbar-fixed-top .navbar-nav > li > a {
  color: #595959;
  font-size: 17px !important;
  padding: 10px 15px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
  color: #fff;
  background-color: #1367b2;
  border-color: #1367b2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.navbar-fixed-top .navbar-nav > li.selected > a {
  padding: 0px 15px !important;
}

.pico-formwrapper {
  display: flex;
  justify-content: center;
}

.formPico {
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 36px 32px;
  font-family: 'Poppins', sans-serif;
}

.formPico h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.formPico h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
}

.signin-form {
  display: flex;
  flex-direction: column;
}

.signin-form > div {
  margin-bottom: 15px;
  /* border-bottom: 1px solid red; */
}

.d-md-flex {
  display: flex;
  flex-direction: column;
}

.d-md-flex .text-md-right {
  margin-left: auto;
  text-align: right;
}

.signin-form .form-group {
  display: flex;
  flex-direction: column;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.signin-form .form-group label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6px 0;
}

.signin-form .form-group .form-control {
  border: 1px solid #26262675;
  box-sizing: border-box;
  border-radius: 8px;
  line-height: 36px;
  padding: 0 10px;
}
.signin-form .form-group .form-control.err {
  border: 1px solid #ba3750;
}
.signin-form .form-group .form-control.btn {
  line-height: 48px;
}

.signin-form .form-group .form-control:focus {
  border-color: #343642;
  box-shadow: 0 0 5px rgb(52 54 66 / 10%);
  outline: none;
}

p.err,
span.err {
  padding: 10px 0 0 0;
  color: #ba3750;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.1%;
}
span.notifi-msg {
  padding: 10px 0 0 0;
  color: #080808;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.1%;
  padding: 0;
}
span.err {
  padding: 0;
}
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 14px;
}
.control .caption {
  position: relative;
  top: 0.2rem;
  color: #888;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.forgot-pass {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2164a0;
  text-decoration: none;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 6px;
  left: 0;
  height: 16px;
  width: 16px;
  background: #e6e6e6;
  border-radius: 4px;
}

.control input:checked ~ .control__indicator:after {
  display: block;
  color: #fff;
}

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  margin-top: -1px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.control input:checked ~ .control__indicator {
  background: #38d39f;
}

.text-center {
  text-align: center;
}

.w-50 {
  padding: 20px 0;
}

.newtosignup {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.1%;
}

.inputwithappendicon {
  display: flex;
  border: 1px solid #26262675;
  border-radius: 8px;
}

.inputwithappendicon div {
  width: 100%;
}

.signin-form .form-group .inputwithappendicon .form-control {
  width: 100%;
  border: none;
}

.eyeIconSpan {
  width: 100%;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1400px) {
  .navbar {
    padding: 60px 0px 20px 0px;
  }

  .pico-container.flexcontainer {
    padding-top: 120px;
  }
}
